import React from 'react';
import { Link } from 'react-router-dom';
import CTA from '../../components/Common/CTA';
import SectionTitle from '../../components/Common/SectionTitle';
import SingleServiceFlip from '../../components/Service/SingleServiceFlip';
import SingleProcess from '../../components/Common/Process';
import VideoStyleTwo from '../../components/Video/VideoStyleTwo';

// Service Icons
import serviceIcon1 from '../../assets/img/service/main-home/1.png';
import serviceIcon2 from '../../assets/img/service/main-home/2.png';
import serviceIcon3 from '../../assets/img/service/main-home/3.png';
import serviceIcon4 from '../../assets/img/service/main-home/4.png';
import serviceIcon5 from '../../assets/img/service/main-home/5.png';
import serviceIcon6 from '../../assets/img/service/main-home/6.png';

const ServiceOneMain = () => {
    return (
        <React.Fragment>
            {/* services-area-start */}
            <div className="rs-services style2 pt-120 pb-120 md-pt-80 md-pb-80">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-4 col-md-6 mb-26">
                            <SingleServiceFlip
                                itemClass="flip-box-inner"
                                serviceImage={serviceIcon1}
                                Title="Mobile App Development (iOS & Android)"
                                TextDesc="High-performance, secure mobile apps with exceptional user experiences for iOS and Android."
                                ButtonClass="readon view-more"
                                ButtonText="Get In Touch"
                            />
                        </div>
                        <div className="col-lg-4 col-md-6 mb-26">
                            <SingleServiceFlip
                                itemClass="flip-box-inner"
                                serviceImage={serviceIcon2}
                                Title="Custom Web Development"
                                TextDesc="Tailored websites with cutting-edge designs, seamless functionality, and scalable web solutions."
                                ButtonClass="readon view-more"
                                ButtonText="Get In Touch"
                            />
                        </div>
                        <div className="col-lg-4 col-md-6 mb-26">
                            <SingleServiceFlip
                                itemClass="flip-box-inner"
                                serviceImage={serviceIcon3}
                                Title="WordPress Solutions & Customization"
                                TextDesc="Custom WordPress development, theme customization, plugin integration, and site optimization for performance."
                                ButtonClass="readon view-more"
                                ButtonText="Get In Touch"
                            />
                        </div>
                        <div className="col-lg-4 col-md-6 mb-26">
                            <SingleServiceFlip
                                itemClass="flip-box-inner"
                                serviceImage={serviceIcon4}
                                Title="Server Management & DevOps"
                                TextDesc="Efficient Server Management & DevOps solutions for optimized performance, automation, and seamless scalability."
                                ButtonClass="readon view-more"
                                ButtonText="Get In Touch"
                            />
                        </div>
                        <div className="col-lg-4 col-md-6 mb-26">
                            <SingleServiceFlip
                                itemClass="flip-box-inner"
                                serviceImage={serviceIcon5}
                                Title="Quality Assurance & Testing"
                                TextDesc="Comprehensive Quality Assurance & Testing to ensure reliability, performance, and bug-free user experiences."
                                ButtonClass="readon view-more"
                                ButtonText="Get In Touch"
                            />
                        </div>
                        <div className="col-lg-4 col-md-6">
                            <SingleServiceFlip
                                itemClass="flip-box-inner"
                                serviceImage={serviceIcon6}
                                Title="Security Optimization & Compliance"
                                TextDesc="Enhancing security and ensuring compliance with industry standards for robust and risk-free operations."
                                ButtonClass="readon view-more"
                                ButtonText="Get In Touch"
                            />
                        </div>
                        {/* <div className="col-lg-4 col-md-6">
                            <SingleServiceFlip
                                itemClass="flip-box-inner"
                                serviceImage={serviceIcon3}
                                Title="Performance Tuning & Scalability"
                                TextDesc="Optimizing performance and scalability to ensure fast, efficient, and future-ready solutions."
                                ButtonClass="readon view-more"
                                ButtonText="Get In Touch"
                            />
                        </div> */}
                    </div>
                </div>
            </div>
            {/* services-area-end */}

            {/* process-area-start */}
            <div className="rs-process style5 bg5 pt-120 pb-120 md-pt-80 md-pb-64">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-4 md-mb-40">
                            <div className="process-wrap">
                                <SectionTitle
                                    sectionClass="sec-title mb-30"
                                    subtitleClass="sub-text new"
                                    subtitle="Working Process"
                                    titleClass="title white-color"
                                    title="Our Working Process -  How We Work For Our Customers"
                                />
                                <div className="btn-part mt-40">
                                    <Link to="/contact">
                                        <a className="readon learn-more contact-us">Contact Us</a>
                                    </Link>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-8 pl-30 md-pl-15">
                            <div className="row">
                                <div className="col-md-6 mb-40">
                                    <SingleProcess
                                        processNumber="1"
                                        processTitle="Discovery"
                                        processText="We first find out the customer's actual requirements and identify business goals. This phase includes detailed research, discovery, and collection of more information."
                                    />
                                </div>
                                <div className="col-md-6 mb-40">
                                    <SingleProcess
                                        processNumber="2"
                                        processTitle="Planning"
                                        processText="We create planning and timelines for the project. We cooperate closely with customers and cover expectations for the project."
                                    />
                                </div>
                                <div className="col-md-6 sm-mb-40">
                                    <SingleProcess
                                        processNumber="3"
                                        processTitle="Execute"
                                        processText="In the Execution, we work on action. Our innovative team works delicately to develop, test, and implement the solution."
                                    />
                                </div>
                                <div className="col-md-6">
                                    <SingleProcess
                                        processNumber="4"
                                        processTitle="Deliver"
                                        processText="After the execution, we deliver it to the customer for review. we verify all the requirements, and testing approaches and provide them to the customer.we provide a smooth operation with customer."
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* process-area-End */}

            {/* Contact Form Start */}
            <VideoStyleTwo />
            {/* Contact Form End */}

            {/* newsletter-area-start */}
            <CTA
                ctaSectionClass="rs-cta style1 cta-bg1 pt-70 pb-70"
                ctaTitleClass="epx-title"
                ctaTitle="Grow Your Business and Build Your Website or Software With us."
                ctaButtonClass="readon learn-more"
                ctaButtonLink="#"
                ctaButtonText="Get In Touch"
            />
            {/* newsletter-area-end */}
        </React.Fragment>
    );
}

export default ServiceOneMain;