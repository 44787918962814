import React from 'react';
import OwlCarousel from 'react-owl-carousel2';
import SectionTitle from '../Common/SectionTitle';
import SingleProject from './SingleProject';


import MobileAppDevelopment from '../../assets/img/project/MobileAppDevelopment.png';
import CustomWebDevelopment from '../../assets/img/project/CustomWebDevelopment.png';
import WordPressSolutions from '../../assets/img/project/WordPressSolutions.png';
import QualityAssurance  from '../../assets/img/project/QualityAssurance.png';
import SecurityOptimization from '../../assets/img/project/SecurityOptimization.png';
import PerformanceTuning  from '../../assets/img/project/PerformanceTuning.png';
import ServerManagement  from '../../assets/img/project/ServerManagement.png';
const Project = () => {

    const options = {
        items: 3,
        nav: true,
        dots: false,
        margin: 30,
        rewind: false,
        autoplay: false,
        stagePadding: 30,
        loop: true,
        navText: ["<i class='fa fa-angle-left'></i>", "<i class='fa fa-angle-right'></i>"],
        center: false,
        responsive: {
            0: {
                stagePadding: 0,
                items: 1,
                nav: false,
            },
            768: {
                items: 2,
                nav: false,
                stagePadding: 0,
            },
            992: {
                items: 2,
                nav: false,
                stagePadding: 0,
            },
            1200: {
                items: 3,
                nav: true,
                stagePadding: 0,
            },
            1500: {
                items: 3,
                stagePadding: 0,
            }
        }
    };

	return(
        <React.Fragment>
            <div id="rs-portfolio" className="rs-project style1 pt-110 md-pt-80 mb--150">
                <div className="container">
                    <SectionTitle
                        sectionClass="sec-title2 text-center mb-44 md-mb-30"
                        subtitleClass="sub-text white-color"
                        subtitle="Project"
                        titleClass="title white-color"
                        title="We Are Offering All Kinds of IT Solutions Services"
                    />
                    <OwlCarousel options={options} >
                        <SingleProject 
                            itemClass="project-item"
                            projectImage={CustomWebDevelopment} 
                            Title="Custom Web Development" 
                            Category="IT Technology" 
                        />
                        <SingleProject 
                            itemClass="project-item"
                            projectImage={MobileAppDevelopment} 
                            Title="Mobile App Development (iOS & Android)" 
                            Category="IT Technology" 
                        />
                        <SingleProject 
                            itemClass="project-item"
                            projectImage={WordPressSolutions} 
                            Title="WordPress Solutions & Customization" 
                            Category="IT Technology" 
                        />
                        <SingleProject 
                            itemClass="project-item"
                            projectImage={ServerManagement} 
                            Title="Server Management & DevOps" 
                            Category="IT Technology" 
                        />
                        <SingleProject 
                            itemClass="project-item"
                            projectImage={QualityAssurance} 
                            Title="Quality Assurance & Testing" 
                            Category="IT Technology" 
                        />
                        <SingleProject 
                            itemClass="project-item"
                            projectImage={SecurityOptimization} 
                            Title="Security Optimization & Compliance" 
                            Category="IT Technology" 
                        />"
                        {/* <SingleProject 
                            itemClass="project-item"
                            projectImage={PerformanceTuning} 
                            Title="Performance Tuning & Scalability" 
                            Category="IT Technology" 
                        />" */}
                    </OwlCarousel>
                </div>
            </div>
        </React.Fragment>
	)
}

export default Project