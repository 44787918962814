import React from 'react';
import { Link } from 'react-router-dom';
import OwlCarousel from 'react-owl-carousel2';

import SectionTitle from '../../components/Common/SectionTitle';
import SingleTeam from '../../components/Team/SingleTeam';
import SingleProcess from '../../components/Process/SingleProcess';
import SinglePricing from '../../components/Pricing/SinglePricing';
import VideoStyleThree from '../../components/Video/VideoStyleThree';


// Working Process Images
import processImage1 from '../../assets/img/process/1.png';
import processImage2 from '../../assets/img/process/2.png';
import processImage3 from '../../assets/img/process/3.png';
import processImage4 from '../../assets/img/process/4.png';

// Working Process Images
import akhilesh from '../../assets/img/team/akhilesh.png';
import antim from '../../assets/img/team/antim.png';
import govind from '../../assets/img/team/govind.png';
import kalyani from '../../assets/img/team/kalyani.png';
import suraj from '../../assets/img/team/suraj.png';
import praveen from '../../assets/img/team/praveen.png';
import aditya from '../../assets/img/team/aditya.png';
import sanjay from '../../assets/img/team/sanjay.png';
import ganesh from '../../assets/img/team/ganesh.png';
import mukesh from '../../assets/img/team/mukeshh.png';
import aadi from '../../assets/img/team/aadi.png';
import aditi from '../../assets/img/team/aditi.png';
import anuj from '../../assets/img/team/anuj.png';
import akansha from '../../assets/img/team/akansha.jpg';
import giriraj from '../../assets/img/team/giriraj.JPEG';
import kuldeep from '../../assets/img/team/kuldeep.JPEG';
import satvik from '../../assets/img/team/satvikk.jpg';



import aboutImg from '../../assets/img/about/about-3.png';
import danceEffectImg from '../../assets/img/about/effect-1.png';

// Pricing Image
import silverImg from '../../assets/img/pricing/1.png';
import goldImg from '../../assets/img/pricing/2.png';
import platinumImg from '../../assets/img/pricing/3.png';

import effectImg1 from '../../assets/img/about/dotted-3.png';
import effectImg2 from '../../assets/img/about/shape3.png';

const AboutMain = () => {

    const options = {
        items: 3,
        nav: false,
        dots: true,
        margin: 30,
        rewind: false,
        autoplay: false,
        stagePadding: 30,
        navText: ["<i class='fa fa-angle-left'></i>", "<i class='fa fa-angle-right'></i>"],
        loop: true,
        center: false,
        responsive: {
            0: {
                stagePadding: 0,
                items: 1,
                dots: false,
            },
            768: {
                items: 2,
                stagePadding: 0,
                dots: true,
            },
            992: {
                items: 3,
                stagePadding: 0,
            },
            1500: {
                items: 3,
                stagePadding: 0,
            }
        }
    };

    return (
        <React.Fragment>
            {/* about-area-start */}
            <div id="rs-about" className="rs-about gray-bg pt-120 pb-120 md-pt-80 md-pb-80">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-6 md-mb-30">
                            <div className="rs-animation-shape">
                                <div className="images">
                                    <img src={aboutImg} alt="" />
                                </div>
                                <div className="middle-image2">
                                    <img className="dance" src={danceEffectImg} alt="" />
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 pl-60 md-pl-14">
                            <div className="contact-wrap">
                                {/* Section Title Start */}
                                <SectionTitle
                                    sectionClass="sec-title mb-30"
                                    subtitleClass="sub-text style-bg"
                                    subtitle="About Us"
                                    titleClass="title pb-36"
                                    title="We Are Increasing Business Success With Technology"
                                    descClass="desc pb-34"
                                    description="Welcome to Techniajz company, which provides software services and IT solutions for everyone. We deliver technology solutions to meet your business needs.
                                   "
                                    secondDescClass="margin-0 pb-16"
                                    secondDescription=" We Contribute to Different types of IT solutions, including mobile app development for both Android and iOS, customized web development, WordPress solutions and optimization, quality assurance and testing for reliability and efficient server management, and DevOps solutions to increase performance and automation.
                                    "
                                />
                                {/* Section Title End */}
                                <div className="btn-part">
                                    <Link className="readon learn-more" to="/contact">Learn-More</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="shape-image">
                        <img className="top dance" src={effectImg1} alt="" />
                        <img className="bottom dance" src={effectImg2} alt="" />
                    </div>
                </div>
            </div>
            {/* about-area-end */}

            {/* working-process-area-start */}
            <div className="rs-process style2 pt-120 pb-112 md-pt-80 md-pb-72">
                <div className="container">
                    <SectionTitle
                        sectionClass="sec-title2 text-center mb-46"
                        subtitleClass="sub-text style-bg"
                        subtitle="Process"
                        titleClass="title title2"
                        title="Our Working Process"
                    />
                    <div className="row">
                        <div className="col-lg-3 col-sm-6 md-mb-50">
                            <SingleProcess
                                itemClass="addon-process"
                                processImage={processImage1}
                                titleClass="title"
                                Title="Discovery"
                            />
                        </div>
                        <div className="col-lg-3 col-sm-6 md-mb-50">
                            <SingleProcess
                                itemClass="addon-process"
                                processImage={processImage2}
                                titleClass="title"
                                Title="Planning"
                            />
                        </div>
                        <div className="col-lg-3 col-sm-6 sm-mb-50">
                            <SingleProcess
                                itemClass="addon-process"
                                processImage={processImage3}
                                titleClass="title"
                                Title="Execute"
                            />
                        </div>
                        <div className="col-lg-3 col-sm-6">
                            <SingleProcess
                                itemClass="addon-process"
                                processImage={processImage4}
                                titleClass="title"
                                Title="Deliver"
                            />
                        </div>
                    </div>
                </div>
            </div>
            {/* working-process-area-end */}
            
            {/* Team Area Start */}
            <div id="rs-team" className="rs-team pt-120 pb-120 md-pt-80 md-pb-80 xs-pb-54">
                <div className="container">
                    <SectionTitle
                        sectionClass="sec-title2 text-center mb-30"
                        subtitleClass="sub-text style-bg white-color"
                        subtitle="Team"
                        titleClass="title white-color"
                        title="Expert IT Consultants"
                    />
                    <OwlCarousel options={options} >
                        <SingleTeam
                            itemClass="team-item-wrap"
                            teamImage={akhilesh}
                            Title="Akhilesh Joshi (AJ)"
                            Designation="FULL Stack Developer"
                            linkedinLink="https://www.linkedin.com/in/akhileshjoshi007/"
                        />
                        <SingleTeam
                            itemClass="team-item-wrap"
                            teamImage={suraj}
                            Title="Suraj Singh"
                            Designation="Senior Developer"
                            linkedinLink="https://www.linkedin.com/in/suraj-singh-150843b5/"
                        />
                    
                        <SingleTeam
                            itemClass="team-item-wrap"
                            teamImage={govind}
                            Title="Govind Prajapat (Gipizz)"
                            Designation="Senior Developer"
                            linkedinLink="https://www.linkedin.com/in/govind-p-7855601b/"
                        />
                        <SingleTeam
                            itemClass="team-item-wrap"
                            teamImage={antim}
                            Title="Antim Songara"
                            Designation="Web Developer"
                            linkedinLink="https://www.linkedin.com/in/antim-singh-20527b21b/"
                        />
                        <SingleTeam
                            itemClass="team-item-wrap"
                            teamImage={kalyani}
                            Title="Kalyani Sharma (Kullu)"
                            Designation="Director"
                            linkedinLink="https://www.linkedin.com/in/kalyani-sharma-431214272/"
                        />
                        <SingleTeam
                            itemClass="team-item-wrap"
                            teamImage={praveen}
                            Title="Praveen Sevta"
                            Designation="FULL Stack Developer"
                            linkedinLink="https://www.linkedin.com/in/praveen-sevta-6b089624/"
                        />
                        <SingleTeam
                            itemClass="team-item-wrap"
                            teamImage={aditya}
                            Title="Aditya Raj Sharma"
                            Designation="FULL Stack Developer"
                            linkedinLink="https://www.linkedin.com/in/adityarajsharma777/"
                        />
                        <SingleTeam
                            itemClass="team-item-wrap"
                            teamImage={sanjay}
                            Title="Sanjay Prajapat"
                            Designation="UI/UX Designer"
                            linkedinLink="https://www.linkedin.com/in/uxsanjay/"
                        />
                        <SingleTeam
                            itemClass="team-item-wrap"
                            teamImage={ganesh}
                            Title="Ganesh Kumavat"
                            Designation="Front-end Developer"
                            linkedinLink="https://www.linkedin.com/in/ganesh-kumawat-59394b27a/"
                        />
                        <SingleTeam
                            itemClass="team-item-wrap"
                            teamImage={mukesh}
                            Title="Mukesh Majoka"
                            Designation="Web Developer"
                            linkedinLink="https://www.linkedin.com/in/mukesh-majoka-886a14211/"
                        />
                        <SingleTeam
                            itemClass="team-item-wrap"
                            teamImage={aadi}
                            Title="Aditya Mishra"
                            Designation="Front-end Developer"
                            linkedinLink="https://www.linkedin.com/in/aditya-mishra08/"
                        />
                        <SingleTeam
                            itemClass="team-item-wrap"
                            teamImage={aditi}
                            Title="Aditi Pareek"
                            Designation="DevOps"
                            linkedinLink="https://www.linkedin.com/in/aditi-pareek-4765b5315/"
                        />
                        <SingleTeam
                            itemClass="team-item-wrap"
                            teamImage={anuj}
                            Title="Anuj Parashar"
                            Designation="FullStack Developer"
                            linkedinLink="https://www.linkedin.com/in/anuj-parashar-8a9227241/"
                        />
                        <SingleTeam
                            itemClass="team-item-wrap"
                            teamImage={akansha}
                            Title="Akansha Jaiswal"
                            Designation="HR Manager"
                            linkedinLink=""
                        />
                        <SingleTeam
                            itemClass="team-item-wrap"
                            teamImage={kuldeep}
                            Title="Kuldeep Singh"
                            Designation="Full Stack Developer"
                            linkedinLink="https://www.linkedin.com/in/kuldeep-singh-99b204280/"
                        />
                        <SingleTeam
                            itemClass="team-item-wrap"
                            teamImage={giriraj}
                            Title="Giriraj Singh Shekhawat"
                            Designation="Full Stack Developer"
                            linkedinLink="https://www.linkedin.com/in/girirajsinghshekhawat/"
                        />
                        <SingleTeam
                            itemClass="team-item-wrap"
                            teamImage={satvik}
                            Title="Satvik Priyadarshi"
                            Designation="Full Stack Developer"
                            linkedinLink="https://www.linkedin.com/in/satvik-priyadarshi-73a20b231/"
                        />
                    </OwlCarousel>
                </div>
            </div>
            {/* Team Area End */}

            {/* pricing-area-start */}
            {/* <div className="rs-pricing style2 gray-bg pt-120 pb-144 md-pt-80 md-pb-80">
                <div className="container">
                    <SectionTitle
                        sectionClass="sec-title2 text-center mb-46"
                        subtitleClass="sub-text style-bg"
                        subtitle="Pricing"
                        titleClass="title title2"
                        title="Our Pricing Plan"
                    />
                    <div className="row">
                        <div className="col-lg-4 md-mb-30">
                            <SinglePricing
                                itemClass="pricing-table new-style2"
                                badge="Silver"
                                pricingImage={silverImg}
                                currency="$"
                                price="29.99"
                                period="Monthly Package"
                                buttonClass="readon buy-now"
                                buttonText="Buy Now"
                                buttonURL="#"

                                // Feature List (1 to 4 item)
                                featureText1="Powerful Admin Panel"
                                ability1="yes"

                                featureText2="1 Native Android App"
                                ability2="yes"

                                featureText3="Multi-Language Support"
                                ability3="no"

                                featureText4="Support via E-mail and Phone"
                                ability4="no"
                            />
                        </div>

                        <div className="col-lg-4 md-mb-30">
                            <SinglePricing
                                itemClass="pricing-table primary-bg"
                                badge="Gold"
                                pricingImage={goldImg}
                                currency="$"
                                price="39.99"
                                period="Monthly Package"
                                buttonClass="readon buy-now"
                                buttonText="Buy Now"
                                buttonURL="#"

                                // Feature List (1 to 4 item)
                                featureText1="Powerful Admin Panel"
                                ability1="yes"

                                featureText2="1 Native Android App"
                                ability2="yes"

                                featureText3="Multi-Language Support"
                                ability3="yes"

                                featureText4="Support via E-mail and Phone"
                                ability4="no"
                            />
                        </div>

                        <div className="col-lg-4">
                            <SinglePricing
                                itemClass="pricing-table new-style2"
                                badge="Platinum"
                                pricingImage={platinumImg}
                                currency="$"
                                price="79.99"
                                period="Monthly Package"
                                buttonClass="readon buy-now"
                                buttonText="Buy Now"
                                buttonURL="#"

                                // Feature List (1 to 4 item)
                                featureText1="Powerful Admin Panel"
                                ability1="yes"

                                featureText2="1 Native Android App"
                                ability2="yes"

                                featureText3="Multi-Language Support"
                                ability3="yes"

                                featureText4="Support via E-mail and Phone"
                                ability4="yes"
                            />
                        </div>
                    </div>
                </div>
            </div> */}
            {/* pricing-area-end */}

            {/* VideoStyleThree area start */}
            {/* <div class="rs-contact-wrap bg7 pt-120 pb-390 md-pt-80">
                <div class="container">
                    <SectionTitle
                        sectionClass="sec-title2 text-center mb-30"
                        subtitleClass="sub-text style-bg white-color"
                        subtitle="Contact"
                        titleClass="title white-color"
                        title="Request A Free Consultation"
                    />
                </div>
            </div> */}
            {/* <VideoStyleThree /> */}
            {/* VideoStyleThree area end */}

        </React.Fragment>
    )
}

export default AboutMain;