import React from 'react';
import OwlCarousel from 'react-owl-carousel2';
import SectionTitle from '../../components/Common/SectionTitle';
import SingleTestimonial from '../../components/Testimonial/SingleTestimonial';


import quote from '../../assets/img/testimonial/quote.png';
import author1 from '../../assets/img/testimonial/satvik2.jpg';
import author2 from '../../assets/img/testimonial/author2.jpg';
import author3 from '../../assets/img/testimonial/author3.png';
import author4 from '../../assets/img/testimonial/author4.png';
import author5 from '../../assets/img/testimonial/author5.png';

function Testimonial() {

    const options = {
        items: 3,
        nav: false,
        dots: true,
        margin: 30,
        rewind: false,
        autoplay: false,
        stagePadding: 30,
        loop: true,
        center: false,
        responsive: {
            0: {
                stagePadding: 0,
                items: 1,
                dots: false,
            },
            768: {
                items: 2,
                stagePadding: 0,
                dots: true,

            },
            992: {
                items: 2,
                stagePadding: 0,

            },
            1200: {
                items: 3,
                stagePadding: 0,
            },
            1500: {
                items: 3,
                stagePadding: 0,
            }
        }
    };

	return(
        <React.Fragment>
            <div className="rs-testimonial main-home gray-bg pt-120 pb-120 md-pt-80 md-pb-80">
                <div className="container">
                    <SectionTitle
                        sectionClass="sec-title2 text-center mb-46 pt-16"
                        subtitleClass="sub-text"
                        subtitle="  "
                        titleClass="title testi-title"
                        title="Reviews"
                        effectClass="heading-line"
                    />
                    <OwlCarousel options={options} >
                        <SingleTestimonial 
                            itemClass="testi-item"
                            quoteImage={quote}
                            authorImage={author1}
                            Title="Satvik Priyadarshi" 
                            // Designation="FullStack Developer" 
                           Description="Offers a supportive and inclusive work environment where employees feel valued 🤝. With a collaborative culture 💬 and regular team-building activities 🎉, it’s a joy to work here. Their focus on wellness is clear through sports events like cricket 🏏 and tournaments 🏆, promoting work-life balance. A fantastic place to grow and thrive! 💼✨
                            "
                        />
                        <SingleTestimonial 
                            itemClass="testi-item"
                            quoteImage={quote}
                            authorImage={author2}
                            Title="Ganesh Kumavat" 
                            // Designation="App Developer" 
                            Description="I had an amazing experience with Curlture! Their customer service is exceptional, always ensuring seamless interactions. The products are wonderful, meeting all my needs. Their attention to detail, fast response, and innovative solutions consistently exceed my expectations. Highly recommended!"
                        />
                        <SingleTestimonial 
                            itemClass="testi-item"
                            quoteImage={quote}
                            authorImage={author3}
                            Title="Akansha Jaiswal" 
                            // Designation="HR manager" 
                            Description="I had an outstanding experience with Techniajz! Their team demonstrated professionalism, knowledge, and dedication. They exceeded expectations with clear communication, creative solutions, and timely delivery. Techniajz's attention to detail and commitment to excellence make them highly recommended for reliable, high-quality services."
                        />
                        <SingleTestimonial 
                            itemClass="testi-item"
                            quoteImage={quote}
                            authorImage={author4}
                            Title="Anuj Parashar" 
                            // Designation="FullStack Developer" 
                            Description="Techniajz exemplifies professionalism, innovation, and a commitment to delivering top-quality results. Their expert team ensures seamless execution, clear communication, and consistently exceeds expectations. With a client-focused approach and attention to detail, Techniajz delivers exceptional outcomes."
                        />
                        <SingleTestimonial 
                            itemClass="testi-item"
                            quoteImage={quote}
                            authorImage={author5}
                            Title="Aditi Pareek" 
                            // Designation="DevOps" 
                            Description="I am currently working on a DevOps internship with Techniajz. The company fosters a great learning environment, providing opportunities for growth. My contributions have been well-received, and I’ve gained valuable experience, showcasing potential for further development in the field."
                        />
                    </OwlCarousel>
                </div>
            </div>
        </React.Fragment>
	)
}

export default Testimonial