import React from "react";
import { Link } from "react-router-dom";
import FooterBottom from "./FooterBottom";

import Newsletter from "../../Common/Newsletter";

import footerLogo1 from "../../../assets/img/logo/logo.png";

const Footer = (props) => {
  const { footerLogo, footerClass } = props;
  return (
    <footer className={footerClass ? footerClass : "rs-footer"}>
      <div className="footer-top">
        <div className="container">
          <div className="row">
            <div className="col-xl-3 col-lg-3 col-md-4">
              <div className="footer-logo">
                <Link to="/">
                  <img src={footerLogo ? footerLogo : footerLogo1} alt="Logo" />
                </Link>
              </div>
              <div className="textwidget pb-30">
                <p>
                Welcome to Techniajz company, which provides software services and IT solutions for everyone. We deliver technology solutions to meet your business needs.
                </p>
              </div>
              <ul className="footer-social md-mb-30">
              <li>
                  <a
                    href="https://www.linkedin.com/company/techniajz/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <i className="fa fa-linkedin"></i>
                  </a>
                </li>
                <li>
                  <a
                    href="https://in.pinterest.com/techniajz/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <i className="fa fa-pinterest"></i>
                  </a>
                </li>

                <li>
                  <a
                    href="https://www.facebook.com/TechniAjz"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <i className="fa fa-facebook"></i>
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.instagram.com/techniajz/?igsh=MTl3Z3pnZHF4cmpneA%3D%3D#"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <i className="fa fa-instagram"></i>
                  </a>
                </li>
              </ul>
            </div>
            <div className="col-lg-3 col-md-12 col-sm-12 pl-44 md-pl-14 md-mb-30">
              <h3 className="widget-title">IT Services</h3>
              <ul className="site-map">
                <li>
                  <Link to="/service/software-development">
                    Custome Web Development
                  </Link>
                </li>
                <li>
                  <Link to="/service/Web-development">
                   Mobile App Development
                  </Link>
                </li>
                <li>
                  <Link to="/service/analytic-solutions">
                    Wordpress Customization
                  </Link>
                </li>
                <li>
                  <Link to="/service/cloud-and-devOps">
                     Server Management 
                  </Link>
                </li>
                <li>
                  <Link to="/service/project-design">
                    Quality Assurance 
                  </Link>
                </li>
                <li>
                  <Link to="/service/project-design">
                  Security Optimization
                  </Link>
                </li>
              </ul>
            </div>
            <div className="col-lg-3 col-md-12 col-sm-12 md-mb-30">
              <h3 className="widget-title">Contact Info</h3>
              <ul className="address-widget">
                <li>
                  <i className="flaticon-location"></i>
                  <div className="desc">
                    1st Floor, 92/62, Patel Marg, Ward 27, Sector 9, Mansarovar,
                    Jaipur, Rajasthan 302021
                  </div>
                </li>
                <li>
                  <i className="flaticon-call"></i>
                  <div className="desc">
                    <Link to="#">(+91)9654652847</Link>
                  </div>
                </li>
                <li>
                  <i className="flaticon-email"></i>
                  <div className="desc">
                    <a
                      href="https://mail.google.com/mail/?view=cm&fs=1&to=info@techniajz.com"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      info@techniajz.com
                    </a>
                  </div>
                </li>
                <li>
                  <i className="flaticon-clock"></i>
                  <div className="desc">
                    Opening Hours: 10 AM - 7PM
                    <br />
                    Saturday , Sunday - Closed
                  </div>
                </li>
              </ul>
            </div>
            <div className="col-lg-3 col-md-12 col-sm-12">
              <h3 className="widget-title">Newsletter</h3>
              <p className="widget-desc">
                We denounce with righteous and in and dislike men who are so
                beguiled and demo realized.
              </p>
              <p>
                {/* newsletter-area-start */}
                <Newsletter />
                {/* newsletter-area-end */}
              </p>
            </div>
          </div>
        </div>
      </div>
      <FooterBottom />
    </footer>
  );
};

export default Footer;
