import SectionTitle from '../../components/Common/SectionTitle';
import SingleService from '../../components/Service/SingleService';

import serviceIcon1 from '../../assets/img/service/icons/1.png';
import serviceIcon2 from '../../assets/img/service/icons/2.png';
import serviceIcon3 from '../../assets/img/service/icons/3.png';
import serviceIcon4 from '../../assets/img/service/icons/4.png';
import serviceIcon5 from '../../assets/img/service/icons/5.png';
import serviceIcon6 from '../../assets/img/service/icons/6.png';

const Service = () => {

    return (
        <div id="rs-service" className="rs-services main-home gray-bg pt-110 pb-120 md-pt-70 md-pb-70">
            <div className="container">
                <SectionTitle
                    sectionClass="sec-title2 text-center mb-46"
                    subtitleClass="sub-text"
                    subtitle="Services"
                    titleClass="title"
                    title="We Are Offering All Kinds of IT Solutions Services"
                    effectClass="heading-line"
                />
                <div className="row">
                    <div className="col-lg-4 col-md-6 mb-30">
                        <SingleService 
                            itemClass="services-item"
                            serviceImage={serviceIcon1}
                            serviceURL="software-development"
                            Title="Mobile App Development (iOS & Android)" 
                            Text="High-performance, secure mobile apps with exceptional user experiences for iOS and Android." 
                        />
                    </div>
                    <div className="col-lg-4 col-md-6 mb-30">
                        <SingleService 
                            itemClass="services-item"
                            serviceImage={serviceIcon2}
                            serviceURL="web-development"
                            Title="Custom Web Development" 
                            Text="Tailored websites with cutting-edge designs, seamless functionality, and scalable web solutions." 
                        />
                    </div>
                    <div className="col-lg-4 col-md-6 mb-30">
                        <SingleService 
                            itemClass="services-item"
                            serviceImage={serviceIcon3}
                            serviceURL="analytic-solutions"
                            Title="WordPress Solutions & Customization" 
                            Text="Custom WordPress development, theme customization, plugin integration, and site optimization for performance." 
                        />
                    </div>
                    <div className="col-lg-4 col-md-6 mb-30">
                        <SingleService 
                            itemClass="services-item"
                            serviceImage={serviceIcon4}
                            serviceURL="clould-devOps"
                            Title="Server Management & DevOps" 
                            Text="Efficient Server Management & DevOps solutions for optimized performance, automation, and seamless scalability." 
                        />
                    </div>
                    <div className="col-lg-4 col-md-6 mb-30">
                        <SingleService 
                            itemClass="services-item"
                            serviceImage={serviceIcon5}
                            serviceURL="product-design"
                            Title="Quality Assurance & Testing" 
                            Text="Comprehensive Quality Assurance & Testing to ensure reliability, performance, and bug-free user experiences." 
                        />
                    </div>
                    <div className="col-lg-4 col-md-6">
                        <SingleService 
                            itemClass="services-item"
                            serviceImage={serviceIcon6}
                            serviceURL="data-center"
                            Title="Security Optimization & Compliance" 
                            Text="Enhancing security and ensuring compliance with industry standards for robust and risk-free operations." 
                        />
                    </div>

                    <div className="col-lg-4 col-md-6">
                        {/* <SingleService 
                            itemClass="services-item"
                            serviceImage={serviceIcon3}
                            serviceURL="data-center"
                            Title="Performance Tuning & Scalability" 
                            Text="Optimizing performance and scalability to ensure fast, efficient, and future-ready solutions." 
                        /> */}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Service;