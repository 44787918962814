import React from "react";
import { Link } from "react-router-dom";

const SingleTeamTwo = (props) => {
  return (
    <div className={props.itemClass}>
      <div className="images-part">
        <img src={props.teamImage} alt={props.Title} />
        <div className="social-icon">
          <ul>
            <li>
              <a
                href="https://www.linkedin.com/company/techniajz/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <i className="fa fa-linkedin"></i>
              </a>
            </li>
            <li>
              <li>
                <a
                  href="https://in.pinterest.com/techniajz/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <i className="fa fa-pinterest"></i>
                </a>
              </li>
              <a
                href="https://www.facebook.com/TechniAjz"
                target="_blank"
                rel="noopener noreferrer"
              >
                <i className="fa fa-facebook"></i>
              </a>
            </li>

            <li>
              <a
                href="https://www.instagram.com/techniajz/?igsh=MTl3Z3pnZHF4cmpneA%3D%3D#"
                target="_blank"
                rel="noopener noreferrer"
              >
                <i className="fa fa-instagram"></i>
              </a>
            </li>
          </ul>
        </div>
      </div>
      <div className="team-content text-center">
        <h4 className="person-name">
          <Link to="/team/team-details">{props.Title}</Link>
        </h4>
        <span className="designation">{props.Designation}</span>
      </div>
    </div>
  );
};

export default SingleTeamTwo;
